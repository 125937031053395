import { LitElement, html, css } from 'lit';
import { createPageRouter } from './page-router';

export default class AppShell extends LitElement {
  static styles = css`
    #shell-container {
      height: 100%;
      width: 100%;
    }

    app-bar {
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 99;
    }

    #scroll-container {
      height: 100%;
      overflow-y: scroll;
      width: 100%;
    }
  `;

  firstUpdated() {
    console.log('test');
    const routerOutlet = this.shadowRoot?.getElementById('router-outlet');

    if (!routerOutlet) {
      throw new Error('router-outlet DOM element could not be retrieved.');
    }

    createPageRouter(routerOutlet);
  }

  render() {
    return html`
      <div id="shell-container">
        <div id="scroll-container">
          <div id="router-outlet"></div>
        </div>
      </div>
    `;
  }
}

customElements.define('app-shell', AppShell);
