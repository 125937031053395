export default {
  aboutUs: 'About Us',
	appLogo:
		'Logo for {{appName}}, showing a cordless phone lying on its back with two ovals drawn on top of it to resemble a smiling face.',
	chatInstructions:
		'Send a text or WhatsApp message to the number below to chat with Alfred! Experience a demo where Alfred will guide you through the process of booking business services. No actual reservation will be placed—it’s just a test run to see how Alfred can streamline your service reservations.',
    chatWithAlfredNow: 'Chat With Alfred Now!',
	close: 'Close',
	contactUs: 'Questions? Contact an AI',
	continue: 'Continue',
	emailCannotBeEmpty: 'Email cannot be empty.',
	emailTooLong: 'The input is too long for a valid email address.',
	enterEmailInstructions:
		'To reveal the phone number for our AI support agent, please enter your email address below.',
  footerAppDescription: '{{appName}} simplifies customer inquiry and request management by providing an endlessly knowledgeable digital guru who always delivers peak performance.',
  footerCopyright: '©2024 Averi Studios Inc. All rights reserved',
  footerJoinWaitlistText: 'Don’t miss out on the opportunity to elevate your business’s customer communications. Join the waitlist for {{appName}} today and be first in line for a smarter and more satisfying customer experience.',
  footerTagline: 'Go Beyond the Limits of Customer Support',
	tagline: 'Instantly boost customer satisfaction with Alfred, your 24/7 digital assistant, expertly handling inquiries and delivering fast results.',
	introText:
		'Meet Alfred: an always-available guru who can effortlessly handle any number of calls about your business’s products, services, hours of operation, and more.',
	introButtonText: 'Try calling an AI!',
	invalidEmailAddress: 'Please enter a valid email address.',
  joinWaitlist: 'Join Waitlist',
	landingPageTitle: 'The Helpful and Tirelessly Dedicated Digital Guru for Your Business',
	openInWhatsApp: 'Open in WhatsApp',
	summary:
		"Use AI to efficiently and accurately answer customer support queries. We know how difficult it can be to scale customer support resources to keep up with the growth of your products. That's why caller queues and unhappy customers will soon be a thing of the past as AI paves the path for intelligent answers that will always keep up with your user base.",
	unexpectedError: 'An unexpected error occurred. Please try again later.',
	valuePropositionSubtitle: 'Guarantee Customer Satisfaction',
	valuePropositionTitle: 'Make Your Customer Experience Rock-Solid',
  viewDemoMenu: 'View Demo Restaurant Menu',
	whyWeCollectInfo:
		'We collect this information in order to gauge interest and provide you with updates and offers.',
};
