import i18next, { t } from 'i18next';
import enTranslations from './lang/en';

import './app-shell';

i18next.init({
	lng: 'en',
	resources: {
		en: { translation: enTranslations },
	},
});

document.title = `${process.env.APP_NAME} - ${t('landingPageTitle')}`;
