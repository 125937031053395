import { Router } from '@vaadin/router';
import './pages/landing';
import './pages/menu';

export const createPageRouter = (routerOutlet: HTMLElement) => {
  const router = new Router(routerOutlet);
  router.setRoutes([
    { path: '/', component: 'landing-page' },
    { path: '/demo-menu', component: 'menu-page' },
    // TODO: Add a "Not Found" page.
    { path: '(.*)', component: 'landing-page' },
  ]);
}
