import { LitElement, TemplateResult, html, css, PropertyValueMap } from 'lit';

class MenuPage extends LitElement {
  static styles = css`
    body {
        font-family: Arial, sans-serif;
        line-height: 1.6;
        margin: 0;
        padding: 0;
        background: #f4f4f4;
    }
    header {
        background: #333;
        color: #fff;
        padding: 10px 20px;
        text-align: center;
    }
    header h1 {
        margin: 0;
    }
    nav ul {
        list-style: none;
        padding: 0;
    }
    nav ul li {
        display: inline;
        margin-right: 10px;
    }
    nav ul li a {
        color: #fff;
        text-decoration:
    }
    #menu, #location, #hours, footer {
      padding: 10px;
    }
    `
    render(): TemplateResult {
    return html`
        <!DOCTYPE html>
    <html lang="en">
    <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Alfred's Gastro Pub</title>
    <link rel="stylesheet" href="styles.css">
    </head>
    <body>
    <header>
        <h1>Alfred's Gastro Pub</h1>
        <nav>
            <ul>
                <li><a href="#menu">Menu</a></li>
                <li><a href="#location">Location</a></li>
                <li><a href="#hours">Hours of Operation</a></li>
            </ul>
        </nav>
    </header>
    <section id="menu">
        <h2>Menu</h2>
        <!-- Snacks/Starters -->
        <h3>Snacks/Starters</h3>
        <p>Pretzel - $11</p>
        <p>Warm Spinach & Artichoke Dip - $14</p>
        <p>Chorizo Cheese Dip - $15</p>
        <!-- Burgers and Sandwiches -->
        <h3>Burgers and Sandwiches</h3>
        <p>Cheese Burger - $15</p>
        <p>Beyond Meat Burger - $12</p>
        <p>Spicy Fried Chicken Sandwich - $15</p>
        <p>Pull Pork Sandwich - $16</p>
        <!-- Salads -->
        <h3>Salads</h3>
        <p>Beet & Goat Cheese Salad - $14</p>
        <p>Garden Green Salad - $9</p>
        <!-- Desserts -->
        <h3>Dessert</h3>
        <p>NY Style Cheesecake - $10</p>
        <p>Key Lime Pie - $9</p>
        <!-- Drinks and Beverages -->
        <h3>Drinks and Beverages</h3>
        <p>Beer options: Various - $9/$11</p>
        <p>House Cider - $7/$9</p>
        <p>Coffee & Tea - $3</p>
        <p>Sparkling Water - $4</p>
        <p>Can of Soda - $4</p>
    </section>
    <section id="location">
        <h2>Location</h2>
        <p>Address: 56 Holland Ave, Main Floor Front Entrance</p>
        <p>Parking: Free street parking. Additional customer parking behind the plaza.</p>
    </section>
    <section id="hours">
        <h2>Hours of Operation</h2>
        <p>Sunday: 2pm - 12am</p>
        <p>Monday: Closed</p>
        <p>Tuesday: Closed</p>
        <p>Wednesday: 2pm - 10pm</p>
        <p>Thursday: 2pm - 12am</p>
        <p>Friday: 2pm - 2am</p>
        <p>Saturday: 2pm - 2am</p>
        <p>Note: Kitchen closes at 9pm daily.</p>
    </section>
    <footer>
        <p>© 2024 Alfred's Gastro Pub</p>
    </footer>
    </body>
    </html>

    `
  }
}

customElements.define('menu-page', MenuPage);
