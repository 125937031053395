import { LitElement, TemplateResult, html, css, PropertyValueMap } from 'lit';
import { Router } from '@vaadin/router';

import { t } from 'i18next';

class LandingPage extends LitElement {
  static styles = css`
    * {
      box-sizing: border-box;
      font-family: var(--font-primary);
    }

    a {
      color: var(--primary);
    }

    a:hover {
      color: var(--primary-highlight);
    }

    a:visited {
      color: var(--primary);
    }

    #page-container {
      align-items: center;
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    #page-content {
      align-items: center;
      display: flex;
      flex-direction: column;
      padding-top: 50px;
      width: 100%;
    }

    #page-content-shrinkwrapped {
      align-items: center;
      display: flex;
      flex-direction: column;
      max-width: 1200px;
      width: 100%;
    }

	#features {
		margin-top: 120px;
		max-width: 800px;
	}

	#features-title {
		text-align: center;
		margin-bottom: 50px;
	}

	.feature-container {
		align-items: flex-start;
		display: flex;
		flex-direction: row;
		gap: 30px;
		margin-bottom: 30px;
	}

	.feature-image {
		height: 50px;
	}

	.feature-text {
		display: flex;
		flex-direction: column;
	}

	.feature-header {
		margin-top: 10px;
		margin-bottom: 5px;
	}

	#data-driven-insights-header {
		margin-top: 15px;
	}

    #chat-ai-dialog {
			align-items: center;
			border: 1px solid gray;
			border-radius: 10px;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			font-family: var(--font-primary);
			height: 100%;
			max-height: 400px;
			max-width: 500px;
			padding: 20px;
			width: 100%;
		}

    #chat-ai-dialog-contents {
			align-items: center;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			height: 100%;
			padding: 10px;
			row-gap: 10px;
			width: 100%;
		}

    #close-enter-email-dialog-text-button {
			align-self: flex-end;
			cursor: pointer;
		}

    #phone-number {
			font-weight: 700;
			font-size: 1.5em;
		    margin-bottom: 10px;
			text-align: center;
		}

	#open-in-whatsapp-link {
		color: var(--primary);
		font-weight: 700;
		font-size: 1.5em;
		text-align: center;
		text-decoration: none;
	}

	#open-in-whatsapp-link:hover {
		color: var(--primary-highlight);
	}

    #top-bar {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      width: 100%;
    }

    #app-name {
      color: var(--primary);
      font-family: var(--font-primary);
      font-size: 25px;
    }

    #app-name a {
      text-decoration: none;
    }

    #top-bar-app-logo {
      aspect-ratio: 1 / 1;
      background-size: 64px 64px;
      cursor: pointer;
      height: 64px;
      margin-right: 8px;
    }

    .button {
      background-color: var(--primary);
      border: none;
      border-radius: 50px;
      color: white;
      cursor: pointer;
      font-family: var(--font-primary);
      font-size: 1.25em;
      font-weight: 700;
      padding: 20px 50px 20px 50px;
    }

    .button-primary {
      background-color: var(--primary);
      color: white;
    }

    .button-primary:hover {
      background-color: var(--primary-highlight);
    }

    .button-secondary {
      background-color: white;
      border: 1px solid var(--primary-highlight);
      color: var(--primary);
    }

    .button-secondary:hover {
      background-color: var(--primary-highlight);
      color: white;
    }

    #top-bar-button {
      margin-left: auto;
    }

    #intro {
      align-items: center;
      display: flex;
      flex-direction: column;
      margin-top: 100px;
      width: 100%;
    }

    #tagline {
      color: var(--primary);
      font-family: Arial;
      font-size: 3.7em;
      max-width: 800px;
      text-align: center;
    }

    #intro-text {
      color: var(--primary);
      font-family: Arial;
      font-size: 1.1em;
      line-height: 1.5em;
      margin-top: 0;
      text-align: center;
      max-width: 500px;
    }

    #intro-buttons {
      display: flex;
      flex-direction: row;
      gap: 10px;
      justify-content: center;
      margin-top: 20px;
    }

    #intro-image-stack {
      align-items: center;
      display: flex;
      flex-direction: column;
      margin-top: 80px;
      width: 100%;
    }

    #intro-image {
      border-radius: 50px;
      max-width: 1000px;
      width: 100%;
      z-index: 2;
    }

    #intro-image-shadow {
      aspect-ratio: 1168/500;
      border-radius: 50px;
      background-color: var(--primary);
      margin-top: -36%;
      max-width: 1168px;
      width: 100%;
      z-index: 1;
    }

    #footer {
      align-items: center;
      background-color: var(--primary);
      border-top-left-radius: 50px;
      border-top-right-radius: 50px;
      display: flex;
      flex-direction: column;
      margin-top: 150px;
      width: 100%;
      padding: 80px 20% 80px 20%;
    }
    
    #footer * p {
      color: white;
    }

    #footer-top {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
	  width: 100%;
    }

    #footer-tagline {
      font-size: 3em;
      max-width: 500px;
    }

    #footer-join-waitlist-section {
      align-items: flext-start;
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    #footer-join-waitlist-section button {
      max-width: 300px;
    }

    #footer-separator-line {
      background-color: white;
      height: 1px;
      margin: 80px 0;
      width: 100%;
    }

    #footer-bottom {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }

    #footer-app-description-section {
      display: flex;
      flex-direction: column;
      gap: 10px;
      max-width: 250px;
    }

    #footer-app-name {
      font-weight: 700;
      font-size: 1.5em;
      margin: 0;
    }

    #footer-links-section a {
      color: white;
      text-decoration: none;
    }

    #footer-links-section a:hover {
      color: white;
    }

    #footer-links-section a:visited {
      color: white;
    }

    #footer-copyright {
      color: white;
      font-size: 0.75em;
      margin-top: 50px;
    }

    @media (max-width: 1000px) {
      #page-container {
        padding-left: 0;
        padding-right: 0;
      }

      #page-content-shrinkwrapped {
        padding-left: 15px;
        padding-right: 15px;
      }

      #tagline {
        font-size: 2.5em;
      }

      #intro {
        margin-top: 10px;
      }

      #intro-image {
        width: 90%;
      }

      #intro-image-stack {
        margin-top: 50px;
      }

	  .feature-header {
		margin-top: 0;
      }

	  #data-driven-insights-header {
		margin-top: 0;
      }

      .button {
        font-size: 1em;
        padding: 10px 30px 10px 30px;
      }

      #footer {
        margin-top: 50px;
        padding: 30px 10% 30px;
        width: 100%;
      }

      #footer-top {
        flex-direction: column;
        justify-content: flex-start;
      }

      #footer-tagline {
        font-size: 2em;
        margin-bottom: 10px;
        text-align: center;
      }

      #footer-separator-line {
        margin: 50px 0;
      }

      #footer-bottom {
        flex-direction: column;
        gap: 30px;
        justify-content: flex-start;
      }

      #footer-app-name {
        text-align: center;
      }

      #footer-app-description-section {
        max-width: 100%;
      }

      #footer-links-section {
        align-self: center;
      }
    }
  `;

  protected firstUpdated(
		_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>,
	): void {
		const topBarAppLogoDiv = this.shadowRoot?.getElementById('top-bar-app-logo');
		if (topBarAppLogoDiv) {
			topBarAppLogoDiv.style.backgroundImage = `url('${this._svgAppLogo()}')`;
      this.requestUpdate();
		}
	}

  render(): TemplateResult {
    return html`
      <dialog style="visibility: hidden;" id="chat-ai-dialog">
				<p
					id="close-enter-email-dialog-text-button"
					@click="${this._onCloseDialogTextButtonClick}"
				>
					${t('close').toUpperCase()}
				</p>
				<div id="chat-ai-dialog-contents">
          <p id="call-instructions">
            ${t('chatInstructions', { appName: process.env.APP_NAME })}
          </p>
          <p id="phone-number">${process.env.AI_PHONE_NUMBER}</p>
		  <a id="open-in-whatsapp-link" target="_blank" rel="noopener noreferrer" href="${`https://api.whatsapp.com/send/?phone=${process.env.AI_PHONE_NUMBER?.replace(/[^0-9]/g, '')}&text=Hi!%20I%E2%80%99d%20like%20to%20learn%20more%20about%20your%20services%20and%20make%20a%20reservation.`}">${t('openInWhatsApp')}</a>
				</div>
			</dialog>
      <div id="page-container">
        <div id="page-content">
          <div id="page-content-shrinkwrapped">
            <div id="top-bar">
              <div id="top-bar-app-logo" @click="${this._onTopBarAppLogoClick}"></div>
              <h1 id="app-name"><a href="/">${process.env.APP_NAME}</a></h1>
            </div>
            <div id="intro">
              <h1 id="tagline">${t('tagline')}</h1>
              <p id="intro-text">${t('introText')}</p>
  			  <div id="intro-buttons">
                <button class="button button-primary" @click="${this._onChatAiButtonClick}">${t('chatWithAlfredNow')}</button>
              </div>
            </div>
			<div id="features">
				<h1 id="features-title">How Alfred Can Empower Your Business</h1>
				<div class="feature-container">
					<img class="feature-image" src="${this._svgInstantEngagement()}" />
					<div class="feature-text">
						<h2 class="feature-header">
							Instant Engagement with Potential Clients
						</h2>
						<p class="feature-paragraph">
							Traditional service agencies rely on simple forms to gather information, resulting in delays while potential clients wait for a response. Our product eliminates this waiting period by providing instant, intelligent responses. Businesses that use our solution can reduce their response time to zero, creating a seamless and immediate connection with potential clients.
						</p>
					</div>
				</div>
				<div class="feature-container">
					<img class="feature-image" src="${this._svgFasterOperations()}" />
					<div class="feature-text">
						<h2 class="feature-header">
							Streamline Your Customer Service Operations
						</h2>
						<p class="feature-paragraph">
							Our product not only engages potential clients instantly but also significantly reduces the need for a large customer service team. By handling initial conversations and providing detailed transcripts of what clients are looking for, businesses can optimize their operations, focusing their human resources on more complex inquiries and client interactions. This efficiency saves time and reduces operational costs.

						</p>
					</div>
				</div>
				<div class="feature-container">
					<img class="feature-image" src="${this._svgDataDrivenInsights()}" />
					<div class="feature-text">
						<h2 class="feature-header" id="data-driven-insights-header">
							Data-Driven Insights for Continuous Improvement
						</h2>
						<p class="feature-paragraph">
							Our product includes a comprehensive dashboard as part of the package, offering businesses real-time data analysis based on conversation summaries. Each conversation is captured and summarized with key metrics, providing valuable insights into client preferences, common inquiries, and areas for service improvement. With this dashboard, businesses can easily access and analyze data to make informed decisions and continuously enhance their service offerings.
						</p>
					</div>
				</div>
			  </div>
			</div>
          </div>
          <div id="footer">
            <div id="footer-top">
              <p id="footer-tagline">${t('footerTagline')}</p>
            </div>
            <div id="footer-separator-line"></div>
            <div id="footer-bottom">
              <div id="footer-app-description-section">
                <p id="footer-app-name">${process.env.APP_NAME}</p>
                <p id="footer-app-description">${t('footerAppDescription', { appName: process.env.APP_NAME })}</p>
              </div>
              <div id="footer-links-section">
                <a href="https://averistudios.com" target="_blank" rel="noopener noreferrer">${t('aboutUs')}</a>
              </div>
            </div>
            <p id="footer-copyright">${t('footerCopyright')}</p>
          </div>
        </div>
      </div>
    `;
  }

  _onChatAiButtonClick() {
    this._sendClickTrackingData('Call Button');
    const chatAiDialog: HTMLDialogElement | null | undefined = <HTMLDialogElement>this.shadowRoot?.getElementById('chat-ai-dialog');
		if (chatAiDialog) {
			chatAiDialog.style.visibility = 'visible';
			chatAiDialog.showModal();
		}
  }

  _onCloseDialogTextButtonClick() {
    const chatAiDialog: HTMLDialogElement | null | undefined = <HTMLDialogElement>this.shadowRoot?.getElementById('chat-ai-dialog');
		if (chatAiDialog) {
			chatAiDialog.style.visibility = 'hidden';
			chatAiDialog.close();
		}
  }

  _onJoinWaitlistButtonClick() {
      this._sendClickTrackingData('Join Waitlist Button');

      window.open('https://docs.google.com/forms/d/e/1FAIpQLSefruZW_KViuiqb_3sPO5KtNQILDhMrhCgmuSG5jKtW7aw1Og/viewform?usp=sf_link', '_blank');
  }

  _onTopBarAppLogoClick() {
    window.location.href = '/';
  }

  _onViewDemoButtonClick() {
    this._sendClickTrackingData('View Demo Menu Button');

    Router.go({ pathname: '/demo-menu' })
  }

  async _sendClickTrackingData(componentName: string) {
		let response: any;

		try {
			response = await fetch(
				`${process.env.BASEROW_CLICK_TRACKING_TABLE_URL}?user_field_names=true`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Token ${process.env.BASEROW_API_TOKEN}`,
					},
					body: JSON.stringify({
						'Clicked Component': componentName,
						'Date Clicked': new Date().toISOString(),
					}),
				},
			);
		} catch (error) {
			console.error('Failed to send click tracking data due to error:' + error);
		}

		if (response) {
			if (!response.ok) {
				console.error(
					'Failed to send click tracking data due to error response.',
				);
			}
		}
	}

  _introImage() {
    return new URL('../../assets/alfred.jpg', import.meta.url);
  }

  _svgAppLogo(): URL {
		return new URL('../../assets/app_logo.png', import.meta.url);
	}

  _svgInstantEngagement(): URL {
		return new URL('../../assets/instant_engagement.svg', import.meta.url);
	}

  _svgFasterOperations(): URL {
		return new URL('../../assets/faster_operations.svg', import.meta.url);
	}

  _svgDataDrivenInsights(): URL {
		return new URL('../../assets/data_driven_insights.svg', import.meta.url);
	}
}

customElements.define('landing-page', LandingPage);
